import React, { Component } from 'react';

class Thumb extends Component {
    
  render() {
      const imgData = this.props;

    return (
      <React.Fragment>
        <img src={imgData.imgData.thumb} alt={imgData.imgData.alt} width='50px' height='50px'/>
      </React.Fragment>
    );
  }
}

export default Thumb;