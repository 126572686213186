import React, { Component } from 'react';
import bg from './tomas-wolf-architect-background.jpg';
import tomas from './tomas-wolf-bg-artist.png';
import linkedin from './In-White-14px.png';
import Full from './Full.js';
import Thumb from './Thumb.js';
import Art from './artwork.json';
import './App.css';

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeIndex: -1,
      type: 'architecture'
    };
  }

  render() {
    const pos = [
    {
      //architecture 1
      bottom: '120px',
      left: '2%',
      transform: 'rotate(5deg)'
    },
    {
      //architecture 2
      bottom: '10px',
      left: '1%',
      transform: 'rotate(-5deg)'
    },
    {
      //architecture 3
      bottom: '150px',
      left: '5%',
      transform: 'rotate(5deg)'
    },
    {
      //architecture 4
      bottom: '20px',
      left: '6%',
      transform: 'rotate(15deg)'
    },
    {
      //architecture 5
      bottom: '130px',
      left: '10%',
      transform: 'rotate(4deg)'
    },
    {
      //architecture 6
      bottom: '50px',
      left: '10%',
      transform: 'rotate(-4deg)'
    },
    {
      //portrait 1
      bottom: '180px',
      right: '55%',
      transform: 'rotate(-5deg)'
    },
    {
      //portrait 2
      bottom: '235px',
      right: '58%'
    },
    {
      //portrait 3
      bottom: '240px',
      right: '54%',
      transform: 'rotate(11deg)'
    },
    {
      //portrait 4
      bottom: '225px',
      right: '50%',
      transform: 'rotate(-14deg)'
    },
    {
      //portrait 5
      bottom: '240px',
      right: '46%',
      transform: 'rotate(-3deg)'
    },
    {
      //people 1
      bottom: '145px',
      right: '47%',
      transform: 'rotate(13deg)'
    },
    {
      //people 2
      bottom: '210px',
      right: '49%',
      transform: 'rotate(-5deg)'
    },
    {
      //people 3
      bottom: '208px',
      right: '45%',
      transform: 'rotate(23deg)'
    },
    {
      //people 4
      bottom: '215px',
      right: '41%',
      transform: 'rotate(-12deg)'
    },
    {
      //painting 1
      bottom: '115px',
      right: '34%',
      transform: 'rotate(-22deg)'
    },
    {
      //painting 2
      bottom: '170px',
      right: '34%',
      transform: 'rotate(22deg)'
    },
    {
      //painting 3
      bottom: '165px',
      right: '30%',
      transform: 'rotate(-5deg)'
    },
    {
      //painting 4
      bottom: '167px',
      right: '26%',
      transform: 'rotate(15deg)'
    },
    {
      //sketch 1
      bottom: '105px',
      right: '18%',
      transform: 'rotate(16deg)'
    },
    {
      //sketch 2
      bottom: '155px',
      right: '17%',
      transform: 'rotate(12deg)'
    },
    {
      //sketch 3
      bottom: '162px',
      right: '13%',
      transform: 'rotate(-2deg)'
    },
    {
      //sketch 4
      bottom: '165px',
      right: '10%',
      transform: 'rotate(-12deg)'
    },
    {
      //buendia 1
      bottom: '100px',
      right: '25%',
      transform: 'rotate(-4deg)'
    },
    {
      //buendia 2
      bottom: '200px',
      right: '36%',
      transform: 'rotate(14deg)'
    },
    {
      //buendia 3
      bottom: '170px',
      right: '22%',
      transform: 'rotate(-24deg)'
    },
    {
      //buendia 4
      bottom: '157px',
      right: '26%',
      transform: 'rotate(-19deg)'
    },
    {
      //buendia 5
      bottom: '185px',
      right: '18%',
      transform: 'rotate(21deg)'
    },
    {
      //buendia 6
      bottom: '190px',
      right: '32%',
      transform: 'rotate(6deg)'
    },
    {
      //buendia 7
      bottom: '162px',
      right: '29%',
      transform: 'rotate(1deg)'
    },
    {
      //busan 1
      bottom: '145px',
      right: '40%',
      transform: 'rotate(-4deg)'
    },
    {
      //busan 2
      bottom: '195px',
      right: '34%',
      transform: 'rotate(-18deg)'
    },
    {
      //busan 3
      bottom: '200px',
      right: '38%',
      transform: 'rotate(17deg)'
    },
    {
      //moustashe 1
      bottom: '95px',
      right: '8%',
      transform: 'rotate(14deg)'
    },
    {
      //moustashe 2
      bottom: '155px',
      right: '9%',
      transform: 'rotate(-17deg)'
    },
    {
      //moustashe 3
      bottom: '162px',
      right: '13%',
      transform: 'rotate(-12deg)'
    },
    {
      //moustashe 4
      bottom: '172px',
      right: '17%',
      transform: 'rotate(7deg)'
    },
    {
      //moustashe 5
      bottom: '174px',
      right: '21%',
      transform: 'rotate(-3deg)'
    },
    {
      //moustashe 6
      bottom: '172px',
      right: '25%',
      transform: 'rotate(13deg)'
    },
    {
      //moustashe 7
      bottom: '179px',
      right: '29%',
      transform: 'rotate(-16deg)'
    },
    {
      //moustashe 8
      bottom: '180px',
      right: '32.5%',
      transform: 'rotate(6deg)'
    }
  ]

  const grayscale = this.state.activeIndex !== -1 ? 'grayscale(100%)' : 'grayscale(0%)';

    return (
      <div className="App">
        <header className="App-header">
          <div className="main-holder">
            <img src={bg} style={{filter: grayscale}} className="Real-bg" alt="Tomas Wolf looking into ocean" />
            <img src={tomas} className="tomas" alt="Tomas Wolf Architect, Artist" />
            <div className="holder">
            {
              Art.map((item, index) => (
                (this.state.type === item.type || item.hero) &&
                <div key={item.name} style={pos[index]} className="thumb" onClick={() => {this.setState({activeIndex: index, type: item.type})}}>
                  <Thumb imgData={item} />
                </div>
              ))
            }
            </div>
            <div className="text-container">
              <div className="text-holder">
                <h1 className="main-header">Tomas Wolf<br/>
                Art Design Architecture Vancouver Canada</h1>
                <p>Founding architect of Studio One Architecture, 1991 Vancouver Canada<br/>
                <a href="mailto:twolf@tomaswolf.ca">twolf@tomaswolf.ca</a>
                <a href="https://www.linkedin.com/in/tomas-wolf-94594113/"><img src={linkedin} alt="linked in logo linking to architect tomas wolf profile" /></a>
                </p>
              </div>
            </div>
            <div className="full-holder">
            {
              Art.map((item, index) => (
                this.state.activeIndex === index &&
                <div key={item.name} className="full" onClick={() => {this.setState({activeIndex: -1, type: 'architecture'})}}>
                    <Full imgData={item} />
                </div>
              ))
            }
            </div>
            
          </div>
          <div className="mobile-holder">
            <img src="/tomas-wolf-architect-architecture-01.jpg" alt="tomas wolf vancouver architecture granville and robson street future shop winners" />
            <img src="/tomas-wolf-architect-architecture-02.jpg" alt="tomas wolf modern architecture concrete residential" />
            <img src="/tomas-wolf-architect-architecture-03.jpg" alt="tomas wolf vancouver sterling residential tower architecture" />
            <img src="/tomas-wolf-architect-architecture-04.jpg" alt="tomas wolf vancouver architecutre restoration building" />
            <img src="/tomas-wolf-architect-architecture-05.jpg" alt="tomas wolf vancouver architecture commercial glass and conrete building" />
            <img src="/tomas-wolf-architect-architecture-06.jpg" alt="tomas wolf vancouver architecture modern condominiums brick" />
            <img src="/tomas-wolf-architect-portrait-art-01.jpg" alt="tomas wolf pencil sketch artwork of man with hat and pin that says the feeling is mutual" />
            <img src="/tomas-wolf-architect-portrait-art-02.jpg" alt="tomas wolf sketch art of man looking like a professor with messed up hair" />
            <img src="/tomas-wolf-architect-portrait-art-03.jpg" alt="tomas wolf pencil sketch artwork of portrait of a man smoking a pipe" />
            <img src="/tomas-wolf-architect-portrait-art-04.jpg" alt="tomas wolf pencil sketch artwork of woman with long, dark hair" />
            <img src="/tomas-wolf-architect-portrait-art-05.jpg" alt="tomas wolf pencil sketch artwork of older man with large white beard" />
            <img src="/tomas-wolf-architect-people-art-01.jpg" alt="tomas wolf painting of three men hanging out on a pier" />
            <img src="/tomas-wolf-architect-people-art-02.jpg" alt="tomas wolf painting of tall woman and short man talking while walking their dogs" />
            <img src="/tomas-wolf-architect-people-art-03.jpg" alt="tomas wolf colored sketch art of packed pub in Czech Republic" />
            <img src="/tomas-wolf-architect-people-art-04.jpg" alt="tomas wolf painting of two men smoking cigars in a cafe" />
            <img src="/tomas-wolf-architect-painting-art-01.jpg" alt="tomas wolf painting of two street entertainers playing a guitar and slide guitar with a woman watching" />
            <img src="/tomas-wolf-architect-painting-art-02.jpg" alt="tomas wolf painting artwork of town square in city in Czech Republic" />
            <img src="/tomas-wolf-architect-painting-art-03.jpg" alt="tomas wolf vibrant abstract painting of forest landscape" />
            <img src="/tomas-wolf-architect-painting-art-04.jpg" alt="tomas wolf vibrant abstract painting of orange trees on the side of railroad tracks" />
            <img src="/tomas-wolf-architect-sketch-art-01.jpg" alt="tomas wolf ink sketch of bustling city scene" />
            <img src="/tomas-wolf-architect-sketch-art-02.jpg" alt="tomas wolf ink sketch artwork of spiers of the tops of buildings in city in Czech Republic" />
            <img src="/tomas-wolf-architect-sketch-art-03.jpg" alt="tomas wolf ink sketch of city square" />
            <img src="/tomas-wolf-architect-sketch-art-04.jpg" alt="tomas wolf rough sketch of sterling architectural tower in vancouver bc" />
            <img src="/tomas-wolf-architect-buendia-art-01.jpg" alt="tomas wolf sketch buendia series two men with gold fish" />
            <img src="/tomas-wolf-architect-buendia-art-02.jpg" alt="tomas wolf sketch buendia series portrait of man" />
            <img src="/tomas-wolf-architect-buendia-art-03.jpg" alt="tomas wolf sketch buendia series portrait of miltary man" />
            <img src="/tomas-wolf-architect-buendia-art-04.jpg" alt="tomas wolf sketch buendia series man on horse" />
            <img src="/tomas-wolf-architect-buendia-art-05.jpg" alt="tomas wolf sketch buendia series portrait of miltary commander" />
            <img src="/tomas-wolf-architect-buendia-art-06.jpg" alt="tomas wolf sketch buendia series group of miltary men" />
            <img src="/tomas-wolf-architect-buendia-art-07.jpg" alt="tomas wolf sketch buendia series portrait of man with gold fish" />
            <img src="/tomas-wolf-architect-architecture-busan-01.jpg" alt="tomas wolf busan architecture competition drawing" />
            <img src="/tomas-wolf-architect-architecture-busan-02.jpg" alt="tomas wolf busan architecture sketch" />
            <img src="/tomas-wolf-architect-architecture-busan-03.jpg" alt="tomas wolf busan architecture competition detailed drawing" />
            <img src="/tomas-wolf-architect-mustache-art-01.jpg" alt="tomas wolf sketch of man with blue hat and a mustache" />
            <img src="/tomas-wolf-architect-mustache-art-02.jpg" alt="tomas wolf sketch of man with a mustache holding his hand out" />
            <img src="/tomas-wolf-architect-mustache-art-03.jpg" alt="tomas wolf sketch of a man with an mustache and small amount of hair under lip" />
            <img src="/tomas-wolf-architect-mustache-art-04.jpg" alt="tomas wolf sketch of man before growing a mustache" />
            <img src="/tomas-wolf-architect-mustache-art-05.jpg" alt="tomas wolf sketch of man before growing a mustache done in felt pen" />
            <img src="/tomas-wolf-architect-mustache-art-06.jpg" alt="tomas wolf sketch of bald man with a handlebar mustache" />
            <img src="/tomas-wolf-architect-mustache-art-07.jpg" alt="tomas wolf self portrait sktech with mustache and goatee" />
            <img src="/tomas-wolf-architect-mustache-art-08.jpg" alt="tomas wolf self of chef cooking with frying pan" />
          </div>
        </header>
      </div>
    );
  }
}

export default App;
