import React, { Component } from 'react';

class Full extends Component {
  
  render() {
    const imgData = this.props;
    const topPos = 50 + Math.round(50*Math.random())
    const rightPos = 50 + Math.round(250*Math.random())
    let rot = 'rotate(0deg)'
    let topOffset = 0
    let rightOffset = 0

    if(imgData.imgData.type === 'portrait' || imgData.imgData.type === 'painting' || imgData.imgData.type === 'people' || imgData.imgData.type === 'sketch' || imgData.imgData.type === 'buendia' || imgData.imgData.type === 'busan') {
      var num = Math.floor(Math.random()*5)
      num *= Math.floor(Math.random()*2) === 1 ? 1 : -1
      rot = `rotate(${num}deg)`
      
      if(num > 0) {
        topOffset = num*4
      } else if (num < 0) {
        rightOffset = num*-4
      }
    }

    return (
      <React.Fragment>
          <img style={{top: topPos + 'px', right: rightPos + 'px', transform: rot}} src={imgData.imgData.main} alt={imgData.imgData.alt} />
          <div style={{top: topPos + 5 + topOffset + 'px', right: rightPos + 5 + rightOffset + 'px', transform: rot}} className="imgClose">x</div>
      </React.Fragment>
    );
  }
}

export default Full;
